<template>
  <v-form
    class="data-card__input"
    ref="form_contact"
    v-model="valid"
    lazy-validation
  >
    <v-row class="info-card" dense no-gutters>
      <v-col
        :cols="row.cols ? row.cols : 12"
        class="info-card__field"
        :class="{'pr-3': calcPadding(i)}"
        v-for="(row, i) in config"
        :key="i"
      >
        <div class="info-card__name">
          {{ row.name }}
        </div>
        <!--        9 ошибок-->
        <v-text-field
          v-if="row.type === 'input' && !(row.dataField === 'confirmPassword' || row.dataField === 'password')"
          v-model="form[row.dataField]"
          :disabled="row.disabled || disabled"
          :rules="row.rules ? [...row.rules] : [rules.required]"
          :type="row.typeInput ? row.typeInput : 'text'"
          color="#00599B"
          outlined
          class="info-card__cell"
          dense
        />
        <!--    инпут телефона с маской российских номеров    -->
        <v-text-field
          v-if="row.type === 'telephone' && ((form.code === '+7' && row.name === 'Рабочий телефон')
           || (form.codeMobile === '+7' && row.name === 'Мобильный телефон'))"
          v-model="form[row.dataField]"
          :disabled="row.disabled || disabled"
          :rules="[rules.inn, rules.required]"
          :type="row.typeInput ? row.typeInput : 'text'"
          color="#00599B"
          v-mask="'(###) ###-##-##'"
          outlined
          class="info-card__cell"
          dense
        />
        <!--    инпут телефона с маской иностранных номеров (без маски)    -->
        <v-text-field
          v-if="row.type === 'telephone' && ((form.code === '+' && row.name === 'Рабочий телефон')
           || (form.codeMobile === '+' && row.name === 'Мобильный телефон'))"
          v-model="form[row.dataField]"
          :disabled="row.disabled || disabled"
          :rules="row.rules"
          :type="row.typeInput ? row.typeInput : 'text'"
          color="#00599B"
          outlined
          class="info-card__cell"
          dense
        />
        <!--    дефолтный инпут телефона    -->
        <!--        2 ошибки      -->
        <v-text-field
          v-if="row.type === 'telephone' && !((form.code === '+7' && row.name === 'Рабочий телефон')
           || (form.codeMobile === '+7' && row.name === 'Мобильный телефон')) && !((form.code === '+' && row.name === 'Рабочий телефон')
           || (form.codeMobile === '+' && row.name === 'Мобильный телефон'))"
          v-model="form[row.dataField]"
          :disabled="row.disabled || disabled"
          :rules="row.rules"
          :type="row.typeInput ? row.typeInput : 'text'"
          color="#00599B"
          outlined
          class="info-card__cell"
          dense
        />
        <!--        :style="form[row.dataField] === '+7' ? 'background: red' : ''"-->
        <v-autocomplete
          v-if="row.type === 'code'"
          v-model="form[row.dataField]"
          :disabled="row.disabled || disabled"
          :rules="row.rules"
          :items="codes"
          color="#00599B"
          outlined
          class="info-card__cell"
          dense
        />
        
        <!--                  @update="updatePhoneValue($event, row.dataField,row.dataField, row.dataField + 'Formatted')"-->
        <!--        <VuePhoneNumberInput-->
        <!--          v-if="row.type === 'telephone'"-->
        <!--          class=""-->
        <!--          :disabled="row.disabled || disabled"-->
        <!--          v-model="value[row.dataField]"-->
        <!--          :only-countries="['RU', '']"-->
        <!--          no-example-->
        <!--          default-country-code="RU"-->
        <!--          valid-color="#263238"-->
        <!--          error-color="#C00000"-->
        <!--          :error="errorNumber"-->
        <!--          color="#546e7a"-->
        <!--          :translations="translations1"-->
        <!--        />-->
        <!--      <VuePhoneNumberInput-->
        
        <!--          v-else-if="row.type === 'telephone'"-->
        <!--          class=""-->
        <!--          v-model="form[row.dataField]"-->
        <!--          :only-countries="['RU']"-->
        <!--          default-country-code="RU"-->
        <!--          valid-color="#263238"-->
        <!--          error-color="#C00000"-->
        <!--          :error="errorNumber"-->
        <!--          color="#546e7a"-->
        <!--          @update="updatePhoneValue($event, row.dataField,row.dataField, row.dataField + 'Formatted')"-->
        <!--          :translations="translations1"-->
        <!--      />-->
        <v-autocomplete
          v-else-if="row.type === 'selector'"
          v-model="form[row.dataField]"
          :items="sprData[row.sprDataName].data"
          :loading="sprData[row.sprDataName].loading"
          :rules="row.rules ? row.rules : [rules.required]"
          label="Выберите..."
          no-data-text="По вашему запросу ничего нет"
          :disabled="row.disabled || disabled"
          dense
          class="info-card__cell"
          clearable
          outlined
        />
      </v-col>
    </v-row>
  </v-form>

</template>

<script>
import './dataCardInput.sass';
// import VuePhoneNumberInput from 'vue-phone-number-input/dist/vue-phone-number-input.common';
// import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  name: "CardInput",
  // components: {
  //   VuePhoneNumberInput,
  // },
  props: {
    value: {
      type: Object,
    },
    sprData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    config: {
      type: Array,
      default: () => {
        return [
          /*
     {
      name: '',
      type: 'input',
      typeInput: 'text',
      isPadding: false,
      cols: 6,
      rules: [rules.required, rules.counter3, rules.rus],
      disabled: false,
      dataField: 'short_name',
      sprDataName: 'orgTypes',
}
          * */
        ]
      }
    },
    canCloseEnter: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      valid: true,
      isOnButtonSave: false,
      errorNumber: false,
      form: {...this.value},
      rules: {
        required: value => !!value || 'Обязательное поле!',
        counter: value => value.length > 10 || 'Поле не может содержать меньше 10 символов',
        counter3: value => value.length >= 3 || 'Поле не может содержать меньше 3 символов',
        dolg: value => value.length >= 5 || 'Поле не может содержать меньше 5 символов',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Неверно указан адрес почты'
        },
      },
      translations1: {
        countrySelectorLabel: 'Код страны',
        countrySelectorError: 'Неверный код страны',
        phoneNumberLabel: '',
        example: 'Пример :'
      },
      codes: [
        {text: 'Россия (код +7)', value: '+7'},
        {text: 'Зарубежье (код +)', value: '+'},
      ],
    }
  },
  created() {
    this.form = {...this.initForm()};
  },
  watch: {
    value: {
      handler() {
        this.form = {...this.initForm()}
      },
      deep: true
    }
  },
  methods: {
    initForm() {
      const obj = {};
      for (const [key, value] of Object.entries(this.value)) {
        obj[key] = (typeof value === 'object') ? value?.id ?? value : value
      }
      return obj
    },
    async validate() {
      this.valid = this.$refs.form_contact.validate()
    },
    async sendData() {
      await this.validate();
      if (this.valid) {
        this.$emit('input', {
          ...this.value,
          ...this.form,
        })
      } else
        this.$emit('error-validation')
    },
    onChange() {
      this.isOnButtonSave = true;
    },
    cancel() {
      this.form = {...this.initForm()};
    },
    calcPadding(index) {
      const row = this.config[index]
      return row.cols && row.cols !== 12 && (index + 1) !== this.config.length && (this.config[index]?.isPadding ?? true)
    }
  }
  
}
</script>

<style lang="sass">
.contacts-autocomplete
  &.theme--light.v-text-field--filled
    > .v-input__control
      > .v-input__slot
        background-color: #E3EFFA

.data-card__input
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus
    -webkit-text-fill-color: #455058
    -webkit-box-shadow: 0 0 0px 1000px #E3EFFA inset
  
  .info-card__name
    color: #455058
    font-size: 14px
    padding-bottom: 6px
    white-space: nowrap
    line-height: 18.75px
    font-weight: 500
  
  .info-card__cell
    &.v-text-field--outlined fieldset :not(.error--text)
      //border-color: #F6F6F6

      border-radius: 7px
      max-height: 32px
    
    &.v-input--is-disabled
      .v-input__slot
        background-color: #F6F6F6 !important
        color: #455A64 !important
    
    fieldset
      border: 1px solid #F6F6F6
      border-radius: 7px
    
    .v-input__slot
      min-height: 18px !important
      max-height: 32px !important
      background-color: #E3EFFA
    
    .v-text-field__details
      margin-bottom: 0
    
    .v-text-field__slot
      height: 32px
      
      input
        color: #455058 !important
  
  .info-card__field
    display: flex
    flex-direction: column

</style>