<template>
  <div>
    <div class="d-flex fade-row justify-space-between align-center">
      <h1 class="main-header__title">Личный кабинет</h1>
      <div class="d-flex flex-row align-center">
        <a href="https://files.ined.ru/files/olipmiad_instructions.pdf"
           class="main-header__subtitle mr-2 text-decoration-underline" target="_blank">Руководство пользователя</a>
        <v-menu
          :close-on-content-click="false"
          :nudge-bottom="20"
          min-width="420px"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <span class="main-header__subtitle main-color__important text-decoration-underline" v-on="on"
                  v-bind="attrs">Обратная связь</span>
          </template>
          <v-card class="">
            <v-card-title>
              Ответственный за сбор данных от ФГБУ «Интеробразование»
            </v-card-title>
            <v-card-text class="d-flex flex-column">
              <div>ФИО: Морозова Анна Антоновна</div>
              <div>Контактный номер рабочего телефона: +7 499 246 66 93</div>
              <div>Контактный номер мобильного телефона :+7 995 886 37 22</div>
              <div>Контактный e-mail: aa.morozova@ined.ru</div>
              <div>Режим работы: пн-пт с 10:00 до 13:00 и с 14:00 до 17:00 (мск)</div>
            </v-card-text>
          </v-card>
        </v-menu>
      
      </div>
    </div>
    <!--    <Alert alert-type="danger">-->
    <!--      <template #icon>-->
    <!--        <v-icon color="#ED393E" size="40">mdi-alert</v-icon>-->
    <!--      </template>-->
    <!--      <template #default>-->
    <!--        <div class="d-flex flex-row justify-space-between align-center">-->
    <!--          <p>Решением Минобрнауки России сбор данных об олимпиадах продлен до 12:00 (мск) 5 августа 2022 года, после завершения сбора данных вход в личный кабинет будет закрыт.</p>-->
    <!--        </div>-->
    <!--      </template>-->
    <!--    </Alert>-->
    
    <div class="mb-10 mt-10 text-center" v-if="!contactsCheck">
      <v-alert
        color="red"
        elevation="4"
        type="error"
      >
        <span class="font-size-large">
          Доступ к формам сбора данных появится только после внесения сведений о непосредственном ответственном исполнителе и подписанте.
        </span>
      </v-alert>
    </div>
    <WrapperPreviewCategory v-for="(item,index) in contacts" :key="index"
                            customClass="section-preview_lk">
      <WrapperCollapse
        :initialOpen="true"
        :title="'Непосредственный ответственный исполнитель ' + (index + 1)"
      >
        <!--        <ContainerPersonContact-->
        <!--            :blocked="getBlock"-->
        <!--            :can-be-deleted="index!==0"-->
        <!--            :contact="item"-->
        <!--            :loading-contacts="loadingContacts"-->
        <!--            :saving-data="savingContact"-->
        <!--            @delete-contact="deleteContact($event, index)"-->
        <!--            @change-contact="editContact($event, index)"-->
        <!--            @add-contact="addContact($event, index)"-->
        <!--        />-->
        <container-data-input-card
          :value="item"
          :config="config"
          :blocked="getBlock"
          can-close-enter
          :can-be-deleted="index !== 0"
          :saving="savingContact"
          :error="errorContact(item, index)"
          @delete-data="deleteContact($event, index)"
          @change-data="editContact($event, index)"
          @add-data="addContact($event, index)"
        >
        </container-data-input-card>
      </WrapperCollapse>
    </WrapperPreviewCategory>
    <WrapperPreviewCategory
      customClass="section-preview_lk">
      <WrapperCollapse
        :initialOpen="true"
      >
        <template #title>ПОДПИСАНТ
          <v-tooltip
            bottom
            max-width="1150px"
            color="primary"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :size="15"
                class="btn__icon grey-light-color__important"
                v-bind="attrs"
                v-on="on"
              >
                mdi-information
              </v-icon>
            </template>
            <p class="white--text">
              Подписантом от лица организации может быть её руководитель (в том числе исполняющий обязанности или
              временно исполняющий обязанности руководителя организации) или иное уполномоченное лицо. Право подписи
              может подтверждаться соответствующим приказом или нотариально заверенной доверенностью. Чтобы подтвердить
              право подписи, приложите к скан-копии подписанных форм с проставленной печатью скан-копию документа,
              устанавливающего за подписантом право подписи.
            </p>
          </v-tooltip>
        </template>
        <container-data-input-card
          :value="contactSigner"
          :config="configSigners"
          :blocked="getBlock"
          can-close-enter
          is-single
          :saving="savingContact"
          :error="errorSigner"
          @change-data="editContactSigner($event)"
        >
        </container-data-input-card>
      </WrapperCollapse>
    </WrapperPreviewCategory>
    
    <div v-if="contactsCheck">
      <header-main
        subtitle=""
        title="Сбор данных об олимпиадах школьников и олимпиадах для лиц, претендующих на обучение по образовательным программам высшего образования – программам магистратуры, с участием иностранных граждан."
      />
      <Alert alert-type="warning">
        <template #icon>
          <v-icon color="#D4990A" size="40">mdi-alert</v-icon>
        </template>
        <template #default>
          <div class="d-flex flex-row justify-space-between align-center">
            <p v-if="getBlock">Формы для подписи и печати</p>
            <p v-else>Формы для самопроверки</p>
            <v-btn
              :loading="downloadingFileForPrint"
              class="success__v-btn"
              @click="downloadPrintDocument"
              color="#29A128"
            ><!--class="button_border" :class="getBlock ? 'blocked-btn': 'blocked-btn'"-->
              <span style="color:#fff;">Скачать</span>
            </v-btn>
          </div>
        </template>
      </Alert>
      <Alert v-if="!getBlock" alert-type="success">
        <template #icon>
          <v-icon color="#29A128" size="40">mdi-check-bold</v-icon>
        </template>
        <template #default>
          <div class="d-flex flex-row justify-space-between align-center">
            <p>{{ getBlock ? 'Ввод данных заблокирован' : 'Заблокировать ввод данных' }}</p>
            <v-btn
              v-if="!getBlock"
              :disabled="getBlock"
              :loading="loadingBlockEnterData"
              class="success__v-btn"
              color="#29A128"
              @click="clickBlockEnterData()"
            ><!--class="button_border" :class="getBlock ? 'blocked-btn': 'blocked-btn'"-->
              <span style="color:#fff;">{{
                  getBlock ? 'Заблокировано' : 'Заблокировать'
                }}</span>
            </v-btn>
          </div>
        </template>
      </Alert>
      <scanLoad v-else></scanLoad>
      <v-expansion-panels class="mb-5" v-for="section in sectionsData" :key="section.id">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h1 class="main-header__title">{{ section.title }}</h1>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="cards">
              <form-card :form="form" :section-path="section.path" v-for="form in section.forms"
                         :key="form.id"/>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <confirm-dialog
      ref="agree-dialog"
      :text="finallyTextForConfirmDialog">
      <template #actions="{agree, close}">
        <v-btn
          class='blue__v-btn'
          @click="agree"
          width="80px"
        >
          Ок
        </v-btn>
        <v-btn
          class='white__v-btn ml-2'
          @click="close"
          width="80px"
        >
          Отменить
        </v-btn>
      </template>
    </confirm-dialog>
  </div>
</template>

<script>
import request from "@/services/request";
import {mapActions, mapGetters} from 'vuex';
import {saveBlobFile} from "@/utils/saveFile";
import HeaderMain from "@/components/headers/HeaderMain";
import WrapperPreviewCategory from '@/components/wrappers/WrapperPreviewCategory';
import WrapperCollapse from '@/components/wrappers/WrapperCollapse';
// import ContainerPersonContact from "@/components/contacts/ContainerPersonContact";
import Alert from "@/components/UI/Alert";
import axios from "axios";
import scanLoad from "@/components/scanLoad";
import FormCard from "@/View/FormCard";
import ConfirmDialog from "@/components/Dialogs/confirmDialog";
import {getBlockInfo} from "@/Api/userInfo";
import ContainerDataInputCard from "@/components/cards/DataCardInput/ContainerDataInputCard";
import contactConfig from "@/View/LK/contactConfig";
import contactSignerConfig from "@/View/LK/contactSignerConfig";
import moment from 'moment';

export default {
  name: "LK",
  components: {
    ContainerDataInputCard,
    ConfirmDialog,
    Alert,
    HeaderMain,
    WrapperPreviewCategory,
    WrapperCollapse,
    // ContainerPersonContact,
    scanLoad,
    FormCard
  },
  data() {
    return {
      orgData: {},
      contacts: [
        {
          lastName: "",
          firstName: "",
          middleName: "",
          email: '',
          position: '',
          workPhoneNumber: '',
          workPhoneNumberExt: '',
          personalPhoneNumber: '',
        },
      ],
      contactSigner: {
        
        lastName: "",
        firstName: "",
        middleName: "",
        position: '',
      },
      errors: [],
      errorSigner: null,
      defaultTextConfirmDialog: 'Внимание! После подтверждения вы не сможете менять данные.<br><br> ',
      textConfirmDialog: '',
      savingContact: false,
      loadingContacts: false,
      errorSaving: null,
      loadingBlockEnterData: false,
      loadingBlockEnterWithoutData: false,
      downloadingFileForPrint: false,
      config: contactConfig,
      configSigners: contactSignerConfig
    }
  },
  created() {
    if (this.orgId) {
      this.getContacts();
      this.getContactSigner();
      this.getOrgData();
    }
    /*TODO remove after worked contact backend*/
    // window.updateContactAllow = (flag) => this.saveUserInfo({...this.getUserInfo, haveContacts: flag})
    // window.updateBlockAllow = (flag) => this.saveUserInfo({...this.getUserInfo, blocked: flag})
  },
  computed: {
    ...mapGetters('auth', {getUserInfo: 'getUserInfo', isUserBlock: 'isUserBlock'}),
    ...mapGetters('keycloak', {getOrgId: 'getOrgId'}),
    getBlock() {
      return !!this.isUserBlock
    },
    finallyTextForConfirmDialog() {
      return this.defaultTextConfirmDialog + ' ' + this.textConfirmDialog;
    },
    orgId() {
      return this.getOrgId
    },
    sectionsData() {
      return this.$store.getters["sectionsAndForms/getSectionsData"]
    },
    contactsCheck() { // проверка добавочного не нужна
      if (this.contacts[0]?.firstName && this.contacts[0]?.lastName
        && this.contacts[0]?.email && this.contacts[0]?.position
        && this.contacts[0]?.workPhoneNumber && this.contacts[0]?.personalPhoneNumber
        && this.contactSigner?.firstName && this.contactSigner?.lastName
        && this.contactSigner?.position) {
        return true
      } else {
        return false
      }
    },
  },
  watch: {
    getAuthUserDataLoading(newValue) {
      if (newValue === false) {
        this.getContacts();
        this.getContactSigner();
        this.getOrgData();
      }
    },
    orgId() {
      this.getContacts();
      this.getContactSigner();
      this.getOrgData();
    }
  },
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    ...mapActions('checkFilled', {fetchCheckFilled: 'fetchCheckFilled'}),
    ...mapActions('auth', {saveUserInfo: 'saveUserInfo'}),
    downloadFileForPrint() {
    
    },
    getQuery(item) {
      return {
        path: item.href,
        query: {orgID: this.$route.query.orgID}
      }
    },
    async getOrgData() {
      const res = await request({
        endpoint: `api/spr/org/${this.orgId}`,
        method: 'get',
      })
      this.orgData = res.data
    },
    async downloadPrintDocument() {
      this.downloadingFileForPrint = true;
      const response = await axios.get(`/api/fullreport/${this.orgId}?isTmp=${!this.getBlock}`, {
        responseType: "blob",
        headers: this.$scripts.Utils.authHeader(),
      });
      this.downloadingFileForPrint = false;
      saveBlobFile(response.data);
    },
    async clickBlockEnterData() {
      this.loadingBlockEnterData = true;
      let agree = false;
      //После потверждения вы не сможете менять данные.
      await getBlockInfo(this.orgId).then(data => {
        if (!data.canBlock)
          this.textConfirmDialog = data.message + '<br><br> Уверены ли вы в том, что хотите заблокировать ввод данных?';
      })
      await this.$refs["agree-dialog"].openDialog().then(data => agree = data);
      if (agree) {
        try {
          this.loadingBlockEnterData = true;
          await this.saveUserInfo({
            ...this.getUserInfo,
            blocked: true,
            blockedDate: moment().format('YYYY-MM-DD HH:mm')
          })
          this.loadingBlockEnterData = false;
        } catch (e) {
          this.loadingBlockEnterData = false;
        }
      }
      this.loadingBlockEnterData = false;
    },
    async getContacts() {
      this.loadingContacts = true;
      const res = await request({
        endpoint: `/api/person/direct/getAll?orgID=${this.orgId}`,
        method: 'get'
      })
      if (res.status === 200) {
        if (res.data.length > 0) {
          this.contacts = res.data.map(el => {
            for (const key of Object.keys(el)) {
              if (el[key]) {
                if (key === 'workPhoneNumber' && el[key].slice(0, 3) === '+7 ') { // отрезаем код +7 из начала телефона
                  el[key] = el[key].slice(3);
                  el['code'] = '+7';
                } else if (key === 'workPhoneNumber' && el[key].slice(0, 2) === '+ ') { // отрезаем код + из начала телефона
                  el[key] = el[key].slice(2);
                  el['code'] = '+';
                } else if (key === 'personalPhoneNumber' && el[key].slice(0, 3) === '+7 ') { // отрезаем код +7 из начала телефона
                  el[key] = el[key].slice(3);
                  el['codeMobile'] = '+7';
                } else if (key === 'personalPhoneNumber' && el[key].slice(0, 2) === '+ ') { // отрезаем код + из начала телефона
                  el[key] = el[key].slice(2);
                  el['codeMobile'] = '+';
                } else {
                  el[key] = el[key] === null ? '' : el[key]
                }
              }
            }
            return el
          });
          this.contacts = res.data
          await this.fetchCheckFilled({contacts: this.contacts})
        }
      } else {
        if (res.status !== 404)
          await this.addNotification({
            notificationStatus: 'error',
            notificationMessage: 'Ошибка получения данных ответственных исполнителей',
            timeout: 5000
          })
      }
      this.loadingContacts = false;
    },
    async getContactSigner() {
      try {
        this.loadingContacts = true;
        const res = await request({
          endpoint: `/api/person/signer/getAll?orgID=${this.orgId}`,
          method: 'get'
        })
        if (res.status === 200)
          if (res.data.length > 0) {
            this.contactSigner = res.data[0]
          }
      } catch (e) {
        this.loadingContacts = false;
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка получения данных ответственных исполнителей',
          timeout: 5000
        })
      }
      
    },
    errorContact(item, index) {
      return this.errors.find(el => el.id === item.id || el.id === index)?.text
    },
    async deleteContact(obj, index) {
      try {
        if (obj?.personID) {
          const res = await request({
            endpoint: `/api/person/direct/delete?personID=${obj.personID}`,
            method: 'get',
          })
          if (res.status === 200) {
            if (res.data.length > 0)
              this.contacts.splice(index, 1)
            this.errors = this.errors.filter(el => el.id !== obj?.personID)
          }
        } else {
          this.contacts.splice(index, 1);
          this.errors = this.errors.filter(el => el.id !== index)
        }
      } catch (e) {
        this.errors.push({id: obj?.personID, text: 'Ошибка удаления данных'})
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка удаления данных ответственного исполнителя ' + (index + 1),
          timeout: 5000
        })
      }
      
    },
    async addContact(event, index) {
      this.contacts.splice(index + 1, 0, {
        fio: '',
        email: '',
        position: '',
        workPhoneNumber: '',
        personalPhoneNumber: '',
        org: {id: this.orgId}
      })
    },
    async editContact(obj, index) {
      try {
        const res = await request({
          endpoint: `/api/person/direct/${obj?.personID ? 'update' : 'add'}`,
          method: 'post',
          body: {
            ...obj,
            workPhoneNumber: `${obj?.code} ${obj?.workPhoneNumber}`, // формирование маски телефона +(7?)-***-***-**-**
            personalPhoneNumber: `${obj?.codeMobile} ${obj?.personalPhoneNumber}`, // формирование маски телефона +(7?)-***-***-**-**
            org: {id: this.orgId},
            personID: obj?.personID,
          },
        })
        if (res.status === 200) {
          // this.contacts.splice(index, 1, res.data);
          await this.saveUserInfo({...this.getUserInfo, haveContacts: !!this.contactsCheck});
          setTimeout(() => {
            this.savingContact = false;
          }, 2000);
          await this.getContacts();
          this.errors = this.errors.filter(el => el.id !== index || el.id !== res.data);
        }
      } catch (e) {
        this.errors.push({id: index, text: 'Ошибка удаления данных'})
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка сохранения данных ответственного исполнителя ' + (index + 1),
          timeout: 5000
        })
      }
    },
    async editContactSigner(obj) {
      try {
        const res = await request({
          endpoint: `/api/person/signer/${obj?.id ? 'update' : 'add'}`,
          method: 'post',
          body: {
            ...obj,
            org: {id: this.orgId},
            personID: obj?.personID,
          },
        })
        if (res.status === 200) {
          this.contactSigner = res.data;
          await this.saveUserInfo({...this.getUserInfo, haveContacts: !!this.contactsCheck});
          setTimeout(() => {
            this.savingContact = false;
          }, 2000);
          this.errorSigner = null
        }
      } catch (e) {
        this.errorSigner = 'Ошибка сохранения данных'
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка сохранения данных подписанта ',
          timeout: 5000
        })
      }
    },
  }
}
</script>

<style lang="sass">
.section-preview_lk
  background: #fff
  border-radius: 6px
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06)
  //border: 1px solid #cdd1d4
  margin-bottom: 20px
  
  
  .lk-list_flex_start
    justify-content: flex-start

.v-expansion-panel-content__wrap
  padding-bottom: 60px

.main-header__title
  font-size: 32px
  line-height: 35.2px
  color: #00599B
  font-family: Roboto, sans-serif
  font-weight: 700
  max-width: 1200px
  margin-bottom: 22px

.cards
  display: grid
  grid-template-columns: 1fr 1fr
  grid-gap: 20px
  width: 100%
  margin: auto


</style>
