export const tablePagination = {
    data() {
        return {
            page: 1,
            userPages: 25,
            pagesPaginate:[
                {value: 25,   text: 25},
                {value: 10000, text: 'все'},
            ]
        }
    },
    watch: {
        lengthData(newValue) {
            if (newValue < 50)
                this.userPages = 10000;
            else
                this.userPages = 25;
        }
    },
    computed: {
        showingServerData() {
            console.log(parseInt(this.searchText));
            if (!parseInt(this.searchText)) {
                return this.serverData.filter(el => (!this.searchText || el.org.name.toLowerCase().search(this.searchText.toLowerCase()) !== -1)).slice((this.page - 1) * this.userPages, (this.page) * this.userPages)
            } else {
                return this.serverData.filter(el => (!this.searchText || el.org.id.toString().includes(this.searchText))).slice((this.page - 1) * this.userPages, (this.page) * this.userPages)
            }
          },
          pagesVuetify() {
            if (!parseInt(this.searchText)) {
                return Math.ceil(this.serverData.filter(el => (!this.searchText || el.org.name.toLowerCase().search(this.searchText.toLowerCase()) !== -1)).length / this.userPages);
            } else {
                return Math.ceil(this.serverData.filter(el => (!this.searchText || el.org.id.toString().search(this.searchText) !== -1)).length / this.userPages);
            }
          },      
    },
    methods: {
        changePage(){
            // this.loadingData = true;
            // setTimeout(() => {this.loadingData = false;}, 300)
        },


    },

}