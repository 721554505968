import rules from "@/utils/rules";

export default [
  {
    name: 'Фамилия',
    type: 'input',
    typeInput: 'text',
    disabled: false,
    cols: 4,
    rules: [rules.required, rules.rus],
    dataField: 'firstName',
  },
  {
    name: 'Имя',
    type: 'input',
    typeInput: 'text',
    disabled: false,
    cols: 4,
    rules: [rules.required, rules.rus],
    dataField: 'lastName',
  },
  {
    name: 'Отчество',
    type: 'input',
    typeInput: 'text',
    disabled: false,
    cols: 4,
    rules: [rules.rus],
    dataField: 'middleName',
  },
  {
    name: 'Должность',
    type: 'input',
    disabled: false,
    cols: 12,
    rules: [rules.required, rules.counter3, rules.rusNum],
    dataField: 'position',
  },
]
