<template>
  <div class="container-data-card">
    <div v-if="$slots.header" class="container-data-card__header">
      <slot name="header">
      </slot>
    </div>
    <div class="container-data-card__body">
      <slot>
        <CardInput
            ref="input-card"
            v-model="dataToChange"
            :config="config"
            :spr-data="sprData"
            :disabled="!editMode"
            @input="requestPersonUpdate($event)"
            :can-close-enter="canCloseEnter"
            @cancel="onCancel"
        />
      </slot>
    </div>
    <div class="container-data-card__actions">
      <slot
          name="actions"
          :add="addData"
          :loading="saving"
          :error="error"
          :blocked="blocked"
          :canDeleted="canBeDeleted"
          :edit="changeEditaStatus"
          :cancel="onCancel"
          :delete="deleteData"
      >
        <template  v-if="error">
          <div class="error--text mr-2">{{error}}</div>
        </template>
        <v-btn v-if="!blocked && !isSingle" class="button white__v-btn mr-2" @click="addData">
          Добавить новый контакт
        </v-btn>
        <v-btn v-if="!blocked" :loading="saving" class="button white__v-btn mr-2" @click="onCancel">
          {{ editMode ? 'Отменить': 'Изменить' }}
        </v-btn>
        <template v-if="editMode">
          <v-btn
              :disabled="savingData"
              @click="sendData"
              @keyup.enter="sendData"
              :loading="savingData || saving"
              :class="['blue__v-btn','mr-2']">
            Сохранить
          </v-btn>
        </template>
        <v-btn
            v-if="canBeDeleted"
            :disabled="savingData"
            @click="deleteData"
            :loading="savingData || saving"
            :class="['error__v-btn']">
          Удалить
        </v-btn>
      </slot>
    </div>
  </div>
</template>

<script>
import CardInput from "@/components/cards/DataCardInput/CardInput";

export default {
  components: {
    CardInput,
  },
  name: 'ContainerDataInputCard',
  props: {
    isSingle: {
      type: Boolean,
      default: false
    },
    sprData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    savingData: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    },
    saving: {
      type: Boolean,
      default: false
    },
    blocked: {
      type: Boolean,
      default: false
    },
    canCloseEnter: {
      type: Boolean,
      default: true
    },
    canBeDeleted: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => {
        return {}
      }
    },
    config: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      editMode: false,
      dataToChange: null,
    }
  },
  watch: {
    value: {
      handler(newValue) {
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        this.dataToChange = this.initContactToChangeForm(newValue);
      },
      deep: true
    }
  },
  created() {
    this.dataToChange = this.initContactToChangeForm(this.value)
    if (this.blocked)
      this.editMode = false
  },
  methods: {
    addData() {
      this.$emit('add-data')
    },
    sendData(){
      this.$refs['input-card'].sendData()
    },
    changeEditaStatus() {
      this.editMode = !this.editMode
    },
    initContactToChangeForm(v) {
      const obj = {};
      for (const [key, value] of Object.entries(v)) {
        obj[key] = (typeof value === 'object') ? value?.id ?? value : value
      }
      return obj;
    },
    deleteData() {
      this.$emit('delete-data', this.value)
    },
    async requestPersonUpdate(obj) {
      await this.$emit('change-data', obj)
      this.editMode = false;
    },
    onCancel() {
      this.$refs["input-card"].cancel();
      this.editMode = !this.editMode;
    },
  },
}
</script>

<style lang="sass">
.info-card__button
  margin-top: 10px
  margin-left: 10px

.container-data-card
  display: flex
  flex-direction: column

  &__header
    padding: 17px 25px
    min-height: 96px
    font-family: 'Raleway', sans-serif
    font-style: normal
    font-weight: 600
    font-size: 17px
    line-height: 20px
    letter-spacing: 0.01em
    font-feature-settings: 'pnum' on, 'lnum' on
    color: #455A64
    border-bottom: 2px solid #EBEFF2

  //&__body
  //  padding: 17px 20px 10px
  &__actions
    //border-top: 2px solid #EBEFF2
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    //padding: 10px 20px 25px

</style>