import rules from "@/utils/rules";

export default [
  {
    name: 'Фамилия',
    type: 'input',
    typeInput: 'text',
    disabled: false,
    cols: 4,
    rules: [rules.required, rules.rus],
    dataField: 'firstName',
  },
  {
    name: 'Имя',
    type: 'input',
    typeInput: 'text',
    disabled: false,
    cols: 4,
    rules: [rules.required, rules.rus],
    dataField: 'lastName'
  },
  {
    name: 'Отчество',
    type: 'input',
    typeInput: 'text',
    disabled: false,
    cols: 4,
    rules: [rules.rus],
    dataField: 'middleName',
  },
  {
    name: 'Должность',
    type: 'input',
    disabled: false,
    cols: 6,
    rules: [rules.required, rules.counter3, rules.rusNum],
    dataField: 'position',
  },
  {
    name: 'Электронная почта',
    type: 'input',
    typeInput: 'text',
    rules: [rules.email],
    disabled: false,
    cols: 6,
    dataField: 'email',
  },
  {
    name: 'Код',
    type: 'code',
    cols: 2,
    disabled: false,
    rules: [rules.required],
    dataField: 'code',
  },
  {
    name: 'Рабочий телефон',
    type: 'telephone',
    cols: 2,
    disabled: false,
    rules: [rules.required, rules.tel],
    dataField: 'workPhoneNumber',
  },
  {
    name: 'Добавочный',
    type: 'input',
    cols: 2,
    rules: [rules.digits],
    disabled: false,
    dataField: 'workPhoneNumberExt',
  },
  {
    name: 'Код',
    type: 'code',
    cols: 2,
    disabled: false,
    rules: [rules.required],
    dataField: 'codeMobile',
  },
  {
    name: 'Мобильный телефон',
    type: 'telephone',
    cols: 4,
    disabled: false,
    rules: [rules.required, rules.tel],
    dataField: 'personalPhoneNumber',
  },
]
